(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name menu.factory:Menu
   *
   * @description
   *
   */
  angular
    .module('menu')
    .factory('Menu', Menu);

  function Menu() {
    var MenuBase = {};
    MenuBase.someValue = 'Menu';
    MenuBase.someMethod = function () {
      return 'Menu';
    };
    return MenuBase;
  }
}());
